import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import LinkButton from '../../components/LinkButton';
import * as api from '../../api';
import * as errors from '../../errors';
import VerifyAccountDialog from './VerifyAccountDialog';
import LoadingBar from '../../components/LoadingBar';
import { LoginResponse } from '../../api/types';
import HelmetComponent from '../../components/HelmetComponent';
import { useFormik } from 'formik';
import { logInValidationSchema } from './validation';
import toast from 'react-hot-toast';
import GoogleOAuthComponent from '../../components/GoogleOAuthComponent';
import { useAuth } from '../../context/AuthContext';
import useApiCall from '../../hooks/UseApiCall';
import InputField from '../../components/InputField';
import { Link } from 'react-router-dom';

export default function LoginPage() {
  const [emailValueForResend, setEmailValueForResend] = useState('');
  const [waitingForServerResponse, setWaitingForServerResponse] =
    useState(false);

  const navigate = useNavigate();
  const authContext = useAuth();
  const apiCall = useApiCall(setWaitingForServerResponse);

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: logInValidationSchema,
    onSubmit: async (values) => {
      onLogin(values.email, values.password);
    },
  });

  const onLogin = async (email: string, password: string) => {
    const response = await apiCall(api.login(password, email), undefined);

    if (response.error === errors.NOT_VERIFIED) {
      setEmailValueForResend(email);
      return;
    }

    const responseData = response.data as LoginResponse;
    authContext.loginUser(responseData.access_token);
  };

  const resendConfirmationMail = async () => {
    const response = await apiCall(
      api.resend(emailValueForResend),
      'Resend successful, you should receive the new confirmation link soon.',
    );

    if (response.error) {
      return;
    }
    setEmailValueForResend('');
  };

  const updateErrorMessage = (errorMessage: string) => {
    toast.error(errorMessage);
  };

  const updateWaitingForServerResponse = (waiting: boolean) => {
    setWaitingForServerResponse(waiting);
  };

  return (
    <div className="">
      <HelmetComponent
        title="Log In | Notify Me"
        description="Log In to Notify Me. Notify me is a simple website tracking and monitoring tool. Track website changes every 15 minutes. Start for free."
        canonicalLink="/login"
      />
      <div className="flex lg:min-h-screen justify-center items-center">
        <form
          className="bg-main-footerLinks bg-opacity-40 p-8 border rounded-xl border-main-letters max-w-sm w-full"
          onSubmit={formik.handleSubmit}
        >
          <div>
            <h1 className="my-4 lg:my-8 font-bold font-heading text-4xl text-main-letters text-center">
              Log In
            </h1>
          </div>
          <div className="mt-4">
            <InputField
              label="email"
              id="email"
              type="email"
              name="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.email && formik.errors.email
                  ? formik.errors.email
                  : undefined
              }
            />
          </div>
          <div className="mt-4">
            <InputField
              label="password"
              id="password"
              type="password"
              name="password"
              value={formik.values.password}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.password && formik.errors.password
                  ? formik.errors.password
                  : undefined
              }
            />
          </div>
          <button className="btn btn-block btn-primary" type="submit">
            Log In
          </button>
          <div className="text-center">
            <Link
              to="/reset_password"
              className="text-xs lg:text-sm hover:underline"
            >
              Forgot your password?
            </Link>
          </div>
          <div className="text-xs lg:text-sm mt-4 text-center">
            <p>
              Don&#39;t have an account?{' '}
              <LinkButton
                onClick={() => navigate('/signup')}
                label="Sign Up!"
              />
            </p>
          </div>
          <div className="divider divider-primary text-xs">or</div>
          <div className="mt-4">
            <GoogleOAuthComponent
              setErrorMessage={updateErrorMessage}
              setWaitingForServerResponse={updateWaitingForServerResponse}
              isSignupFlow={false}
            />
          </div>
          <div className="mt-4 text-center">
            <LoadingBar isLoading={waitingForServerResponse} />
          </div>

          <div>
            {emailValueForResend !== '' ? (
              <VerifyAccountDialog onClick={resendConfirmationMail} />
            ) : (
              <></>
            )}
          </div>
        </form>
      </div>
    </div>
  );
}
