export const canyonBikesPath =
  'symplifying_the_canyon_bike_sales_hunt_a_practical_guide_to_tracking';
export const affiliateLinksPath =
  'boost_your_affiliate_marketing_revenue_with_effective_link_checking_strategies';
export const navigatingTheWorldOfWebsiteMonitoringPath =
  'navigating_the_world_of_website_monitoring';
export const monitorCompetitorWebsiteChangesStepByStepGuidePath =
  'monitor_competitor_website_changes_a_step_by_step_guide';
export const monitorWebsiteContentChangesEffectivelyPath =
  'monitor_website_content_changes_effectively';
export const competitiveIntelligenceSystemUserGuidePath =
  'competitive_intelligence_system_user_guide';
export const trackPriceChangesOnWebsitesEssentialsTipsPath =
  'track_price_changes_on_websites_essentials_tips';
export const webPageWatcherEsseentialsPath = 'web_page_watcher_essentials';
export const emailNotificationServicesForProductivityPath =
  'email_notification_services_for_productivity';
export const checkWebsiteStatusOnlineABegginersGuidePath =
  'check_website_status_online_a_begginers_guide';
export const onlineMonitoringToolsABeginnersGuidePath =
  'online_monitoring_tools_a_beginners_guide';
export const monitorWebsiteChangesABeginnersGuidePath =
  'monitor_website_changes_a_beginners_guide';
export const competitiveIntelligenceBasicsPath =
  'competitive_intelligence_basics';
export const automateWebsiteChangeAlertsWithTelegramApiPath =
  'automate_website_change_alerts_with_telegram_api';
export const pushNotificationPricingGuide2024Path =
  'push_notification_pricing_guide_2024';
export const eightEthicalWebScrapingBestPractices2024Path =
  'eight_ethical_web_scraping_best_practices_2024';
export const webChangeTrackingAutomatedVsManualMethodsPath =
  'web_change_tracking_automated_vs_manual_methods';
export const realTimePriceAlertsGuideForEcommerce2024Path =
  'real_time_price_alerts_guide_for_ecommerce_2024';
export const eCommercePriceMonitoringCaseStudiesAndGuide2024Path =
  'ecommerce_price_monitoring_case_studies_and_guide_2024';
export const setUpWebsiteChangeAlertsIn6StepsPath =
  'set_up_website_change_alerts_in_6_steps';
export const howPricingImpactsBrandLoyaltyAnalysisPath =
  'how_pricing_impacts_brand_loyalty_analysis';
