import React from 'react';
import { Link } from 'react-router-dom';

export default function FAQSection() {
  return (
    <>
      <div className="text-main-background max-w-7xl m-auto">
        <div className="text-center p-8 lg:p-24">
          <p className="text-center text-3xl lg:text-5xl xl:text-6xl font-heading ml-auto">
            FAQ
          </p>
          <div className="md:grid md:grid-cols-2 md:gap-12 mt-4 text-left">
            <div className="mt-2">
              <h3 className="text-base md:text-lg text-left font-bold">
                1. Can I track password protected websites with Notify Me?
              </h3>
              <p className="mt-2">
                No, Notify Me does not support tracking password protected
                sites, as it requires a login to access the content.
              </p>
              <p className="mt-2">
                Storing your login credentials would be a security risk and most
                likely a breach of ToS of the site in question, so we do not
                support this feature.
              </p>
            </div>
            <div className="mt-2">
              <h3 className="text-base md:text-lg text-left font-bold">
                2. Change I want to track is only visible if I click something
                on the page, can you track that?
              </h3>
              <p className="mt-2">
                No, Notify Me does not support tracking content that is
                available only after some action is done on the site. Content
                must be visible without any interactions.
              </p>
            </div>
            <div className="mt-2">
              <h3 className="text-base md:text-lg text-left font-bold">
                3. Do you offer an API?
              </h3>
              <p className="mt-2">
                We currently don&apos;t offer an API, but feel free to reach out
                to us if you have any specific use case in mind. We can probably
                work something out.
              </p>
            </div>
            <div className="mt-2">
              <h3 className="text-base md:text-lg text-left font-bold">
                4. Do you track the whole site or just the specific page?
              </h3>
              <p className="mt-2">
                We <b>only</b> track a specific page you provide us with. We do
                not automatically track the whole site.
              </p>
              <p className="mt-2">
                If you want to track multiple pages, you need to add them
                separately.
              </p>
              <p className="mt-2">
                If you have a need for tracking the whole site, checkout our
                other product{' '}
                <Link
                  to="https://competitiontracker.co?ref=https://notify-me.rs"
                  target="blank"
                  className="underline hover:no-underline"
                >
                  Competition Tracker
                </Link>
                .
              </p>
            </div>
            <div className="mt-2">
              <h3 className="text-base md:text-lg text-left font-bold">
                5. What notification channels do you support?
              </h3>
              <p className="mt-2">
                We currently support email notifications as a base way of
                communication. We also have the option for Discord or Telegram
                integration depending on the plan you are on.
              </p>
            </div>
            <div className="mt-2">
              <h3 className="text-base md:text-lg text-left font-bold">
                6. I&apos;m getting a lot of useless notifications, what can I
                do?
              </h3>
              <p className="mt-2">
                We are always working on improving our notification system, but
                it&apos;s possible that you are getting notifications for
                changes that are not relevant to you. In that case, try using
                our keywords feature to filter out the noise.
              </p>
              <p className="mt-2">
                If that also doesn&apos;t work, reach out to us and we&apos;ll
                try to help you out.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
