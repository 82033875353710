import React from 'react';
import FeaturedSection from './FeaturedSection';
import SingleArticlePreview from './SingleArticlePreview';
import { useNavigate } from 'react-router-dom';
import {
  affiliateLinksPath,
  canyonBikesPath,
  monitorCompetitorWebsiteChangesStepByStepGuidePath,
  navigatingTheWorldOfWebsiteMonitoringPath,
  monitorWebsiteContentChangesEffectivelyPath,
  competitiveIntelligenceSystemUserGuidePath,
  trackPriceChangesOnWebsitesEssentialsTipsPath,
  webPageWatcherEsseentialsPath,
  emailNotificationServicesForProductivityPath,
  checkWebsiteStatusOnlineABegginersGuidePath,
  onlineMonitoringToolsABeginnersGuidePath,
  monitorWebsiteChangesABeginnersGuidePath,
  competitiveIntelligenceBasicsPath,
  automateWebsiteChangeAlertsWithTelegramApiPath,
  pushNotificationPricingGuide2024Path,
  eightEthicalWebScrapingBestPractices2024Path,
  webChangeTrackingAutomatedVsManualMethodsPath,
  realTimePriceAlertsGuideForEcommerce2024Path,
  eCommercePriceMonitoringCaseStudiesAndGuide2024Path,
  setUpWebsiteChangeAlertsIn6StepsPath,
  howPricingImpactsBrandLoyaltyAnalysisPath,
} from './paths';
import canyonImage from './articles/img/canyonBikeSales.png';
import affiliateImage from './articles/img/affiliateLinks/affiliateLinksTracking.png';
import navigatingTheWorldOfWebsiteMonitoringImage from './articles/img/navigateTheWorldOfWebsiteMonitoring.png';
import monitorCompetitorWebsiteChangesStepByStepGuideImage from './articles/img/monitorCompetitorWebsiteChangesStepByStepGuide.jpg';
import monitorWebsiteContentChangesEffectivelyImage from './articles/img/monitorWebsiteContentChangesEffectively.jpg';
import competiveIntelligenceSystemUserGuideImage from './articles/img/competitiveIntelligenceSystemUserGuide.jpg';
import trackPriceChangesOnWebsitesEsssentialsTipsImage from './articles/img/trackPriceChangesOnWebsitesEssentialsTips.jpg';
import webPageWatcherEssentialsImage from './articles/img/webPageWatcherEssentials.jpg';
import emailNotificationServicesForProductivityImage from './articles/img/emailNotificationServicesForProductivity.jpg';
import checkWebsiteStatusOnlineABegginersGuideImage from './articles/img/CheckWebsiteStatusOnlineABegginersGuide.jpg';
import onlineMonitoringToolsABeginnersGuideImage from './articles/img/onlineMonitoringToolsABeginnersGuide.jpg';
import monitorWebsiteContentChangesABeginnersGuideImage from './articles/img/monitorWebsiteContentChangesABeginnersGuide.jpg';
import competitiveIntelligenceBasicsImage from './articles/img/competitiveIntelligenceSystemBasicsImage.png';
import automateWebsiteChangeAlertsWithTelegramApiImage from './articles/img/automateWebsiteChangeAlertsWithTelegramApi.jpg';
import pushNotificationPricingGuide2024Image from './articles/img/pushNotificationPricingGuide2024.jpg';
import eightEthicalWebScrapingBestPractices2024Image from './articles/img/eightEthicalWebScrapingBestPractices2024.jpg';
import webChangeTrackingAutomatedVsManualMethodsImage from './articles/img/webChangeTrackingAutomatedVsManualMethods.jpg';
import realTimePriceAlertsGuideForEcommerce2024Image from './articles/img/realTimePriceAlertsGuideForEcommerce2024.jpg';
import eCommercePriceMonitoringCaseStudiesAndGuide2024Image from './articles/img/eCommercePriceMonitoringCaseStudiesAndGuide2024.jpg';
import setUpWebsiteChangeAlertsIn6StepsImage from './articles/img/setUpWebsiteChangeAlertsIn6Steps.jpg';
import howPricingImpactsBrandLoyaltyAnalysisImage from './articles/img/howPricingImpactsBrandLoyaltyAnalysis.jpg';

export default function MainSection() {
  const navigate = useNavigate();

  return (
    <div>
      <FeaturedSection
        imageSrc={howPricingImpactsBrandLoyaltyAnalysisImage}
        title="How Pricing Impacts Brand Loyalty: Analysis"
        description={
          'Explore how pricing strategies influence brand loyalty across various industries and discover key tactics for success.'
        }
        onClick={() => navigate(howPricingImpactsBrandLoyaltyAnalysisPath)}
      />
      <div className="p-4">
        <p className="text-main-letters text-3xl md:text-4xl">
          Latest Articles
        </p>
      </div>

      <div className="md:grid md:grid-cols-4">
        <SingleArticlePreview
          imageSrc={howPricingImpactsBrandLoyaltyAnalysisImage}
          title="How Pricing Impacts Brand Loyalty: Analysis"
          path={howPricingImpactsBrandLoyaltyAnalysisPath}
        />
        <SingleArticlePreview
          imageSrc={setUpWebsiteChangeAlertsIn6StepsImage}
          title="Set Up Website Change Alerts in 6 Steps"
          path={howPricingImpactsBrandLoyaltyAnalysisPath}
        />
        <SingleArticlePreview
          imageSrc={setUpWebsiteChangeAlertsIn6StepsImage}
          title="Set Up Website Change Alerts in 6 Steps"
          path={setUpWebsiteChangeAlertsIn6StepsPath}
        />
        <SingleArticlePreview
          imageSrc={eCommercePriceMonitoringCaseStudiesAndGuide2024Image}
          title="E-commerce Price Monitoring: Case Studies & Guide 2024"
          path={eCommercePriceMonitoringCaseStudiesAndGuide2024Path}
        />
        <SingleArticlePreview
          imageSrc={realTimePriceAlertsGuideForEcommerce2024Image}
          title="Real-Time Price Alerts: Guide for Ecommerce 2024"
          path={realTimePriceAlertsGuideForEcommerce2024Path}
        />
        <SingleArticlePreview
          imageSrc={webChangeTrackingAutomatedVsManualMethodsImage}
          title="Web Change Tracking: Automated vs Manual Methods"
          path={webChangeTrackingAutomatedVsManualMethodsPath}
        />
        <SingleArticlePreview
          imageSrc={eightEthicalWebScrapingBestPractices2024Image}
          title="8 Ethical Web Scraping Best Practices 2024"
          path={eightEthicalWebScrapingBestPractices2024Path}
        />
        <SingleArticlePreview
          imageSrc={pushNotificationPricingGuide2024Image}
          title="Push Notification Pricing Guide 2024"
          path={pushNotificationPricingGuide2024Path}
        />
        <SingleArticlePreview
          imageSrc={automateWebsiteChangeAlertsWithTelegramApiImage}
          title="Automate Website Change Alerts with Telegram API"
          path={automateWebsiteChangeAlertsWithTelegramApiPath}
        />
        <SingleArticlePreview
          imageSrc={competitiveIntelligenceBasicsImage}
          title="Competitive Intelligence System Basics"
          path={competitiveIntelligenceBasicsPath}
        />
        <SingleArticlePreview
          imageSrc={monitorWebsiteContentChangesABeginnersGuideImage}
          title="Monitor Website Content Changes: A Beginner's Guide"
          path={monitorWebsiteChangesABeginnersGuidePath}
        />
        <SingleArticlePreview
          imageSrc={onlineMonitoringToolsABeginnersGuideImage}
          title="Online Monitoring Tools: A Beginner's Guide"
          path={onlineMonitoringToolsABeginnersGuidePath}
        />
        <SingleArticlePreview
          imageSrc={checkWebsiteStatusOnlineABegginersGuideImage}
          title="Check Website Status Online: A Beginner's Guide"
          path={checkWebsiteStatusOnlineABegginersGuidePath}
        />
        <SingleArticlePreview
          imageSrc={emailNotificationServicesForProductivityImage}
          title="Email Notification Services for Productivity"
          path={emailNotificationServicesForProductivityPath}
        />
        <SingleArticlePreview
          imageSrc={webPageWatcherEssentialsImage}
          title="Web Page Watcher Essentials"
          path={webPageWatcherEsseentialsPath}
        />
        <SingleArticlePreview
          imageSrc={trackPriceChangesOnWebsitesEsssentialsTipsImage}
          title="Competitive Intelligence System User Guide"
          path={trackPriceChangesOnWebsitesEssentialsTipsPath}
        />
        <SingleArticlePreview
          imageSrc={competiveIntelligenceSystemUserGuideImage}
          title="Competitive Intelligence System User Guide"
          path={competitiveIntelligenceSystemUserGuidePath}
        />
        <SingleArticlePreview
          imageSrc={monitorWebsiteContentChangesEffectivelyImage}
          title="Monitor Website Content Changes Effectively"
          path={monitorWebsiteContentChangesEffectivelyPath}
        />
        <SingleArticlePreview
          imageSrc={monitorCompetitorWebsiteChangesStepByStepGuideImage}
          title="Monitor Competitor Website Changes: A Step-by-Step Guide"
          path={monitorCompetitorWebsiteChangesStepByStepGuidePath}
        />
        <SingleArticlePreview
          imageSrc={navigatingTheWorldOfWebsiteMonitoringImage}
          title="Navigating the World of Website Monitoring: Free Tools and Advanced Solutions for 2024"
          path={navigatingTheWorldOfWebsiteMonitoringPath}
        />
        <SingleArticlePreview
          imageSrc={affiliateImage}
          title="Boost Your Affiliate Marketing Revenue With Effective Link Checking Strategies"
          path={affiliateLinksPath}
        />
        <SingleArticlePreview
          imageSrc={canyonImage}
          title="Symplifying the Canyon Bike Sales Hunt: A Practical Gude to Tracking"
          path={canyonBikesPath}
        />
      </div>
    </div>
  );
}
