import React from 'react';
import { Helmet } from 'react-helmet-async';
import defaultImg from '../../img/default.png';

interface Props {
  title: string;
  description: string;
  canonicalLink?: string;
  type?: 'article' | 'website';
  imageSrc?: string;
  keywords?: string;
}

const getJsonLdSchema = (props: Props) => {
  if (props.canonicalLink == '/blog') {
    return <></>;
  }

  if (props.type === 'article') {
    return (
      <script type="application/ld+json">
        {`
          {
            "@context": "https://schema.org",
            "@type": "Article",
            "headline": "${props.title}",
            "image": "${calculateOpenGraphImgPath(props)}",
            "author": {
              "@type": "Organization",
              "name": "Notify Me"
            },
            "publisher": {
              "@type": "Organization",
              "name": "Notify Me",
              "logo": {
                "@type": "ImageObject",
                "url": "https://notify-me.rs${defaultImg}"
              }
            },
            "datePublished": "2024-08-12T00:00:00+00:00",
            "dateModified": "2024-08-12T00:00:00+00:00"
          }
        `}
      </script>
    );
  }
  return (
    <script type="application/ld+json">
      {`
        {
          "@context": "https://schema.org",
          "@type": "WebPage",
          "name": "${props.title}",
          "description": "${props.description}",
          "url": "https://notify-me.rs${props.canonicalLink}",
          "image": "${calculateOpenGraphImgPath(props)}",
          "author": {
            "@type": "Organization",
            "name": "Notify Me"
          },
          "publisher": {
            "@type": "Organization",
            "name": "Notify Me",
            "logo": {
              "@type": "ImageObject",
              "url": "https://notify-me.rs${defaultImg}"
            }
          },
          "datePublished": "2024-08-12T00:00:00+00:00",
          "dateModified": "2024-08-12T00:00:00+00:00"
        }
      `}
    </script>
  );
};

const calculateOpenGraphType = (props: Props) => {
  if (props.type === undefined) {
    return <meta property="og:type" content="website" />;
  }
  return <meta property="og:type" content={props.type} />;
};

const calculateOpenGraphImgPath = (props: Props): string => {
  if (props.imageSrc === undefined) {
    return `https://notify-me.rs${defaultImg}`;
  }
  return `https://notify-me.rs${props.imageSrc}`;
};

const calculateKeywords = (props: Props) => {
  if (props.keywords === undefined) {
    return null;
  }
  return <meta name="keywords" content={props.keywords} />;
};

export default function HelmetComponent(props: Props) {
  return (
    <Helmet>
      <title>{props.title}</title>
      <meta charSet="utf-8" />
      {props.canonicalLink !== undefined ? (
        <meta
          property="og:url"
          content={`https://notify-me.rs${props.canonicalLink}`}
        />
      ) : (
        <meta property="og:url" content="https://notify-me.rs" />
      )}
      {props.canonicalLink !== undefined ? (
        <link
          rel="canonical"
          href={`https://notify-me.rs${props.canonicalLink}`}
        />
      ) : null}
      {calculateKeywords(props)}
      <meta name="description" content={props.description} />
      <meta property="twitter:description" content={props.description} />
      <meta property="twitter:title" content={props.title} />
      <meta property="og:title" content={props.title} />
      {calculateOpenGraphType(props)}
      <meta property="og:description" content={props.description} />
      <meta
        property="twitter:image"
        content={calculateOpenGraphImgPath(props)}
      />
      <meta property="og:image" content={calculateOpenGraphImgPath(props)} />
      {getJsonLdSchema(props)}
    </Helmet>
  );
}
