import React, { useState } from 'react';
import LinkButton from '../../components/LinkButton';
import * as api from '../../api';
import LoadingBar from '../../components/LoadingBar';
import { useNavigate } from 'react-router';
import HelmetComponent from '../../components/HelmetComponent';
import { useFormik } from 'formik';
import { signUpValidationSchema } from './validation';
import toast from 'react-hot-toast';
import { EventType, registerEvent } from '../../plausible';
import GoogleOAuthComponent from '../../components/GoogleOAuthComponent';
import useApiCall from '../../hooks/UseApiCall';
import useQueryParam from '../../hooks/UseQueryParam';
import { createSearchParams } from 'react-router-dom';
import InputField from '../../components/InputField';

export default function SignupPage() {
  const [waitingForServerResponse, setWaitingForServerResponse] =
    useState(false);

  const navigate = useNavigate();
  const apiCall = useApiCall(setWaitingForServerResponse);
  const queryParam = useQueryParam();
  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      confirmPassword: '',
    },
    validationSchema: signUpValidationSchema,
    onSubmit: async (values) => {
      registerEvent(EventType.SIGNUP_INITIAL);
      onSignup(values.email, values.password);
    },
  });

  const packageName = queryParam('package');

  const onSignup = async (email: string, password: string) => {
    await apiCall(
      api.signup(password, email, packageName),
      'Sign Up successful, if the email you entered is valid you should receive confirmation link soon (make sure to check your spam also).',
    );
  };

  const updateErrorMessage = (errorMessage: string) => {
    toast.error(errorMessage);
  };

  const updateWaitingForServerResponse = (waiting: boolean) => {
    setWaitingForServerResponse(waiting);
  };

  return (
    <div className="h-[80%]">
      <HelmetComponent
        title="Sign Up | Notify Me"
        description="Sign Up to Notify Me for free. Start tracking website changes today. Notify Me is a free website change monitoring tool. Get alerted when a website changes."
        canonicalLink="/signup"
      />
      <div className="flex lg:min-h-screen justify-center items-center">
        <form
          className="bg-main-footerLinks bg-opacity-40 p-8 border rounded-xl border-main-letters max-w-sm w-full"
          onSubmit={formik.handleSubmit}
        >
          <div>
            <h1 className="my-4 lg:my-8 font-bold font-heading text-4xl text-main-letters text-center">
              Sign Up
            </h1>
          </div>
          <InputField
            label="email"
            id="email"
            type="email"
            name="email"
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.email && formik.errors.email
                ? formik.errors.email
                : undefined
            }
          />

          <InputField
            label="password"
            id="password"
            type="password"
            name="password"
            value={formik.values.password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.password && formik.errors.password
                ? formik.errors.password
                : undefined
            }
          />
          <InputField
            label="confirm password"
            id="confirmPassword"
            type="password"
            name="confirmPassword"
            value={formik.values.confirmPassword}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.confirmPassword && formik.errors.confirmPassword
                ? formik.errors.confirmPassword
                : undefined
            }
          />
          <button className="btn btn-block btn-primary" type="submit">
            Sign Up
          </button>

          <div>
            <div className="text-xs lg:text-sm mt-4 text-center">
              <p>
                Already have an account?{' '}
                <LinkButton
                  onClick={() => {
                    if (packageName !== null) {
                      return navigate({
                        pathname: '/login',
                        search: createSearchParams({
                          package: packageName,
                        }).toString(),
                      });
                    }
                    navigate('/login');
                  }}
                  label="Log In!"
                />
              </p>
            </div>

            <div className="divider divider-primary text-xs">or</div>

            <div className="mt-4">
              <GoogleOAuthComponent
                setErrorMessage={updateErrorMessage}
                setWaitingForServerResponse={updateWaitingForServerResponse}
                isSignupFlow={true}
              />
            </div>

            <div className="mt-4 text-center">
              <LoadingBar isLoading={waitingForServerResponse} />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
