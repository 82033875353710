import React, { useEffect } from 'react';
import HelmetComponent from '../../components/HelmetComponent';
import { EventType, registerEvent } from '../../plausible';

export default function TermsOfUse() {
  useEffect(() => {
    registerEvent(EventType.TERMS_OF_USE);
  }, []);

  return (
    <div className="">
      <HelmetComponent
        title="Terms of Use | Notify Me"
        description="Terms of Use page for Notify Me. Notify Me is a free website change monitoring tool. Get alerted when a website changes. Start for free."
        canonicalLink="/terms_of_use"
      />
      <div className="p-8 lg:px-56 lg:py-10 mt-14 lg:mt-2 text-left text-main-footerLinks">
        <h1 className="nm-default-page-heading">Terms of Use</h1>
        <div className="mt-8">
          <div>
            <p className="text-lg border-b">1. Terms</p>
            <div>
              <div>
                <p className="text-sm mt-4">
                  By accessing the website at{' '}
                  <a
                    href="https://notify-me.rs/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-main-letters underline hover:no-underline"
                  >
                    https://notify-me.rs/
                  </a>
                  , you are agreeing to be bound by these terms of service, all
                  applicable laws and regulations, and agree that you are
                  responsible for compliance with any applicable local laws. If
                  you do not agree with any of these terms, you are prohibited
                  from using or accessing this site. The materials contained in
                  this website are protected by applicable copyright and
                  trademark law.
                </p>
              </div>
            </div>
          </div>
          <div className="mt-4">
            <p className="text-lg border-b">2. Use licence</p>
            <div>
              <div>
                <ul className="text-sm list-[lower-latin] list-inside mt-4">
                  <li>
                    Permission is granted to temporarily download one copy of
                    the materials (information or software) on Notify Me
                    d.o.o.&apos;s website for personal, non-commercial
                    transitory viewing only. This is the grant of a license, not
                    a transfer of title, and under this license you may not:
                    <ul className="text-sm list-decimal list-inside mt-4 pl-4">
                      <li>modify or copy the materials;</li>
                      <li>
                        use the materials for any commercial purpose, or for any
                        public display (commercial or non-commercial);
                      </li>
                      <li>
                        attempt to decompile or reverse engineer any software
                        contained on Notify Me d.o.o.&apos; website;
                      </li>
                      <li>
                        remove any copyright or other proprietary notations from
                        the materials; or
                      </li>
                      <li>
                        transfer the materials to another person or
                        &quot;mirror&quot; the materials on any other server.
                      </li>
                    </ul>
                  </li>
                  <li>
                    This license shall automatically terminate if you violate
                    any of these restrictions and may be terminated by Notify Me
                    d.o.o. at any time. Upon terminating your viewing of these
                    materials or upon the termination of this license, you must
                    destroy any downloaded materials in your possession whether
                    in electronic or printed format.
                  </li>
                </ul>
              </div>
            </div>
            <div className="mt-4">
              <p className="text-lg border-b">3. Disclaimer</p>
              <div>
                <div>
                  <ul className="text-sm list-[lower-latin] list-inside mt-4">
                    <li>
                      The materials on Notify Me d.o.o.&apos;s website are
                      provided on an &apos;as is&apos; basis. Notify Me d.o.o.
                      makes no warranties, expressed or implied, and hereby
                      disclaims and negates all other warranties including,
                      without limitation, implied warranties or conditions of
                      merchantability, fitness for a particular purpose, or
                      non-infringement of intellectual property or other
                      violation of rights.
                    </li>
                    <li>
                      Further, Notify Me d.o.o. does not warrant or make any
                      representations concerning the accuracy, likely results,
                      or reliability of the use of the materials on its website
                      or otherwise relating to such materials or on any sites
                      linked to this site.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="mt-4">
              <p className="text-lg border-b">4. Limitations</p>
              <div>
                <div>
                  <p className="text-sm mt-4">
                    In no event shall Notify Me d.o.o. or its suppliers be
                    liable for any damages (including, without limitation,
                    damages for loss of data or profit, or due to business
                    interruption) arising out of the use or inability to use the
                    materials on Notify Me d.o.o.&apos;s website, even if Notify
                    Me d.o.o. or a Notify Me d.o.o. authorized representative
                    has been notified orally or in writing of the possibility of
                    such damage. Because some jurisdictions do not allow
                    limitations on implied warranties, or limitations of
                    liability for consequential or incidental damages, these
                    limitations may not apply to you.
                  </p>
                </div>
              </div>
            </div>
            <div className="mt-4">
              <p className="text-lg border-b">5. Accuracy of materials</p>
              <div>
                <div>
                  <p className="text-sm mt-4">
                    The materials appearing on Notify Me d.o.o.&apos;s website
                    could include technical, typographical, or photographic
                    errors. Notify Me d.o.o. does not warrant that any of the
                    materials on its website are accurate, complete or current.
                    Notify Me d.o.o. may make changes to the materials contained
                    on its website at any time without notice. However Notify Me
                    d.o.o. does not make any commitment to update the materials.
                  </p>
                </div>
              </div>
            </div>
            <div className="mt-4">
              <p className="text-lg border-b">6. Links</p>
              <div>
                <div>
                  <p className="text-sm mt-4">
                    Notify Me d.o.o. has not reviewed all of the sites linked to
                    its website and is not responsible for the contents of any
                    such linked site. The inclusion of any link does not imply
                    endorsement by Notify Me d.o.o. of the site. Use of any such
                    linked website is at the user&apos;s own risk.
                  </p>
                </div>
              </div>
            </div>
            <div className="mt-4">
              <p className="text-lg border-b">7. Modifications</p>
              <div>
                <div>
                  <p className="text-sm mt-4">
                    Notfiy Me d.o.o. may revise these terms of service for its
                    website at any time without notice. By using this website
                    you are agreeing to be bound by the then current version of
                    these terms of service.
                  </p>
                </div>
              </div>
            </div>
            <div className="mt-4">
              <p className="text-lg border-b">8. Governing Law</p>
              <div>
                <div>
                  <p className="text-sm mt-4">
                    These terms and conditions are governed by and construed in
                    accordance with the laws of Belgrade, Serbia and you
                    irrevocably submit to the exclusive jurisdiction of the
                    courts in that State or location.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
